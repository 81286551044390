html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
	background-color: #f8f9fa!important;
}
select[name=allowedCountries] {
	height:190px !important;
}